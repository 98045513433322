$color-primary: #4D1177;
$color-secondary: #FF00C8;
$breakpoint-desktop: 1180px;
$breakpoint-tablet: 820px;
$breakpoint-mobile: 520px;


@font-face {
    src: url("/assets/Alien-Encounters-Regular.ttf") format("opentype");
    font-family: "AlienEncounters";
  }

.text-primary {
    color: $color-primary;
}

.text-shadow-black {
    text-shadow:
        2px 2px 0 #000,
        -2px 2px 0 #000,
        -2px -2px 0 #000,
        2px -2px 0 #000;
}

.btn {
    border-radius: .3rem;
    text-transform: uppercase;
    font-weight: 900;
    padding: .5rem 1rem;
    transition: all .15s;

    &.btn-white {
        background-color: #FFF;
        color: $color-primary;

        &:hover {
            background-color: $color-secondary;
            color: #FFF;
        }
    }

    &.btn-purple-shadow {
        padding: 1rem;
        border: 2px solid #FFF;
        border-radius: 1.8rem;
        background-color: #6F00FF;
        color: #FFF;
        box-shadow: 0px .8rem 0px #FD00CA;

        &:hover {
            transform: translateY(.8rem);
            box-shadow: 0px 0px 0px #FD00CA;
            background-color: $color-secondary;
        }
    }
}

.btn-dotted {
    border-radius: .8rem;
    border: 2px solid #FFF;
    background-color: #ff00c864;
    position: relative;
    display: flex;
    min-height: 4rem;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 900;
    padding: 1rem 2rem;

    .left-dot {
        &::before {
            content: '';
            position: absolute;
            top: 8px;
            left: 8px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #FFF;
            background-color: #423948;
        }

        &::after {
            content: '';
            position: absolute;
            top: 8px;
            right: 8px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #FFF;
            background-color: #423948;
        }
    }

    .right-dot {
        &::before {
            content: '';
            position: absolute;
            bottom: 8px;
            left: 8px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #FFF;
            background-color: #423948;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 8px;
            right: 8px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #FFF;
            background-color: #423948;
        }
    }
}

.btn-dotted-white {
    border-radius: 1.5rem;
    border: 3px solid #000;
    background-color: #FFF;
    position: relative;
    display: flex;
    min-height: 4rem;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 900;
    padding: 1rem 1rem;
    box-shadow: 0 1rem 0 $color-secondary;
    transition: all .15s;


    &.small {
        min-height: auto;
        padding: .5rem;
        border-radius: 1rem;
        box-shadow: 0 .5rem 0 $color-secondary;

        &:hover {
            transform: translateY(.5rem);
        }
    }

    .left-dot {
        &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 10px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #000;
        }

        &::after {
            content: '';
            position: absolute;
            top: 10px;
            right: 10px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #000;
        }
    }

    .right-dot {
        &::before {
            content: '';
            position: absolute;
            bottom: 10px;
            left: 10px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #000;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid #000;
        }
    }

    &:hover {
        transform: translateY(1rem);
        box-shadow: 0 0 0 $color-secondary;
    }
}

.navbar {
    background-color: #000000a3;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 100;
    backdrop-filter: blur(3px);

    @media (width < $breakpoint-desktop) {
        background-color: #000000d6;

        .btn-menu {
            display: block;

            img {
                opacity: 0;
                visibility: hidden;
                transition: all .3s;
                width: 32px;
            }

            .menu {
                opacity: 1;
                visibility: visible;
                position: absolute;
            }

            &.show {
                .menu {
                    opacity: 0;
                    visibility: hidden;
                }

                .close {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .menu-container {
            padding-top: 1rem;
            position: absolute;
            width: 100%;
            height: 100svh;
            left: -100%;
            top: 52px;
            background-color: rgba(0, 0, 0, 0.6);
            transition: all .3s;
            opacity: 0;

            &.show {
                left: 0%;
                opacity: 1;
            }

            .menu-item {
                padding: 1rem;
            }

            .btn-white {
                // padding: 1rem;
                // background: transparent;
                // color: #FFF;
                margin: .5rem;
                color: #000;
            }
        }
    }

    .menu {
        .menu-item {
            font-weight: 900;
            color: #FFF;
            text-transform: uppercase;
            margin: 0 1rem;
            transition: all .15s;

            @media(width >=$breakpoint-desktop) {
                &:hover {
                    color: $color-secondary;
                    text-shadow:
                        2px 2px 0 #FFF,
                        -2px 2px 0 #FFF,
                        -2px -2px 0 #FFF,
                        2px -2px 0 #FFF;
                }
            }
        }
    }
}

.footer {
    background-color: #000000a3;
    z-index: 1;
    position: relative;

    a {
        font-weight: 900;
        color: #FFF;
        text-transform: uppercase;
        margin: 0 1rem;
        transition: all .15s;

        @media(width >=$breakpoint-desktop) {
            &:hover {
                color: $color-secondary;
                text-shadow:
                    2px 2px 0 #FFF,
                    -2px 2px 0 #FFF,
                    -2px -2px 0 #FFF,
                    2px -2px 0 #FFF;
            }
        }
    }
}

section {
    background-size: cover !important;
    background-position: center !important;
    // min-height: 100svh;
    position: relative;
}

.marquee {
    color: #FFF;
    text-transform: uppercase;
    font-size: 6rem;
    font-weight: 900;
    -webkit-text-fill-color: #21003b;
    text-shadow:
        2px 2px 0 #FFF,
        -2px 2px 0 #FFF,
        -2px -2px 0 #FFF,
        2px -2px 0 #FFF;
    --gap: 1rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);

    li {
        display: flex;
        align-items: center;
    }

    .circle {
        font-size: 4rem;
        margin-left: 26px;
        margin-right: 26px;
        margin-bottom: 10px;
        -webkit-text-fill-color: #FFF;
        text-shadow: none;
    }

    @media (width <=$breakpoint-tablet) {
        font-size: 4rem;

        .circle {
            font-size: 3rem;
            margin-left: 12px;
        }
    }

    @media (width <=$breakpoint-mobile) {
        font-size: 2rem;
        text-shadow:
            1px 1px 0 #FFF,
            -1px 1px 0 #FFF,
            -1px -1px 0 #FFF,
            1px -1px 0 #FFF;

        .circle {
            font-size: 1rem;
            margin-bottom: 0;
        }
    }
}

.marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    gap: var(--gap);
    animation: scroll 30s linear infinite;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}

#homepage {
    background: url('./assets/images/home-bg.webp');
    min-height: 100svh;

    .counter {
        font-family: 'AlienEncounters';
    }

    .home-button {
        position: absolute;
        bottom: 16px;
        left: calc(50% - 150px);
        width: 300px;

        @media (width <=$breakpoint-tablet) {
            left: calc(50% - 100px);
            width: 200px;
        }

        @media(width <=$breakpoint-mobile) {
            left: calc(50% - 50px);
            width: 100px;
            bottom: 6px;
        }

        .main {
            position: relative;
            transform-origin: bottom center;
        }

        img {
            transition: all .1s linear;
        }

        .hover {
            position: absolute;
            bottom: 0;
            transform-origin: bottom center;
            animation: button-blinking 2.5s linear infinite;
            z-index: 1;
        }

        &:hover {
            .main {
                opacity: 0;
                transform: scaleY(0.9);
            }

            .hover {
                opacity: 1;
                filter: drop-shadow(2px -18px 13px #FFF);
                transform: scaleY(0.9);
                animation: none;
            }
        }
    }

    .btn-dotted {
        animation: home-blinking 3s linear infinite;
        margin: 3rem auto 0 auto;
        color: #FFF;
        border-radius: 1.6rem;
        display: none;
        background-color: #ff00c8c5;
    }

    @media (width <=$breakpoint-mobile) {
        background: url('./assets/images/home-mobile-bg.webp');

        .home-button,
        .home-screen>img {
            display: none;
        }

        .btn-dotted {
            display: flex;
        }
    }

    @keyframes home-blinking {
        0% {
            background-color: #ff00c8c5;
        }

        50% {
            background-color: $color-secondary;
            box-shadow: 0px 0px 10px 1px #FFF;
        }

        100% {
            background-color: #ff00c8c5;
        }
    }
}

@keyframes button-blinking {
    0% {
        opacity: 1;
        filter: drop-shadow(2px -18px 13px #FFF);
    }

    30% {
        opacity: 0;
        filter: drop-shadow(none);
    }

    60% {
        opacity: 1;
        filter: drop-shadow(2px -18px 13px #FFF);
    }

    100% {
        opacity: 1;
        filter: drop-shadow(2px -18px 13px #FFF);
    }
}

#built-for-web3 {
    background: url('./assets/images/built-bg.webp');

    #home-button-target {
        min-height: 100svh;
    }

    .animate-gradient {
        position: relative;
        overflow: hidden;

        &::before {
            width: 60%;
            height: 150%;
            position: absolute;
            left: 0;
            top: -50%;
            opacity: 1;
            content: '';
            transform: rotate(25deg);
            background: linear-gradient(to right, transparent, transparent, transparent, white);
            animation: slide-gradient 3s linear infinite;
        }

        &:hover {
            &::before {
                opacity: 0;
            }
        }
    }

    @keyframes slide-gradient {
        0% {
            left: -100%;
        }

        40% {
            left: -100%;
        }

        70% {
            left: 100%;
        }

        100% {
            left: 100%;
        }
    }

    .zook-action {
        display: flex;
        justify-content: center;
        height: 60svh;

        @media(width < $breakpoint-desktop) {
            .zook-default {
                .zook-text {
                    opacity: 1;

                    .main-text {
                        transform: translateY(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }

        .zook {
            position: absolute;
            bottom: 0;
            width: 60%;
            max-width: 1500px;
            transition: all .3s;
            opacity: 0;
            
            img {
                transition: all .3s;
                width: 100%;
            }
        }

        .zook-line {
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            position: absolute;
            width: 1;
        }

        .zook-text {
            color: #FFF;
            position: absolute;
            transition: all .15s;

            .main-text {
                font-size: 2rem;
                text-transform: uppercase;
                font-weight: 900;
                line-height: 1;
                margin-bottom: 1rem;
            }

            .sub-text {
                font-size: .8rem;
            }
        }

        .zook-default {
            z-index: 5;
            opacity: 1;
        }

        .zook-1 {
            z-index: 4;
        }

        .zook-2 {
            z-index: 3;
        }

        .zook-3 {
            z-index: 5;
        }

        // Loop through zook classes for opacity
        @for $i from 1 through 4 {
            &.zook-#{$i} {

                .zook-default {
                    opacity: 0;
                }

                .zook-#{$i} {
                    opacity: 1;
                }

                .zook-line {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    @media (width>=$breakpoint-desktop) {
        .zook-action {
            .zook-text {
                display: none;
            }
        }
    }

    @media (width < $breakpoint-desktop) {
        .zook-action {
            .zook {
                width: 70%;
            }
        }
    }

    @media (width < $breakpoint-desktop) {
        min-height: auto;

        .zook-action {
            height: 600px;

            .zook {
                width: 100%;
            }

            .zook-text {
                position: relative;
                width: 80% !important;
                margin: auto;
                // transform: translateY(-50%) !important;

                .main-text,
                .sub-text {
                    text-align: center;

                    br {
                        display: none;
                    }
                }
            }

            .zook-line {
                display: none;
            }
        }
    }

    @media (width <=$breakpoint-mobile) {

        .btn-purple-shadow {
            font-weight: 700;
            padding: .3rem .5rem;
            border-radius: .8rem;
            font-size: .8rem;
            width: 40%;

            br {
                display: none;
            }
        }

        .zook-action {
            height: 400px;

            .zook-text {
                // transform: translateY(0) !important;

                .main-text {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

#start-building {
    background: url('./assets/images/start-building-bg.webp');
    padding-top: 6rem;
    min-height: 100svh;

    @media(width <=$breakpoint-mobile) {
        min-height: 75svh;
    }

    .title {
        text-shadow:
            4px 4px 0 #000,
            5px 0px 0 #000,
            -4px 4px 0 #000,
            -4px -4px 0 #000,
            4px -4px 0 #000;
    }

    .code {
        font-family: "JetBrains Mono", monospace;
        border: 2px solid #FFF;
        border-radius: .5rem;
        padding: .8rem 1rem;
        background-color: #423948;
        color: #FFF;
        transition: all .15s;

        &:hover {
            background-color: #FD00CA;
        }
    }

    @media (width <=$breakpoint-mobile) {
        .title {
            text-shadow:
                2px 2px 0 #000,
                2px 0px 0 #000,
                -2px 2px 0 #000,
                -2px -2px 0 #000,
                2px -2px 0 #000;
        }


        .code {
            padding: .5rem;
        }
    }
}

#all-aboard {
    background: url('./assets/images/all-aboard-bg.webp');

    .btn-dotted {
        position: relative;
        // overflow: hidden;

        span {
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            animation: btn-blinking 2s linear infinite;
            border-radius: .8rem;
        }

        &:hover {
            box-shadow: 0px 0px 8px 1px #FFF;
            background-color: $color-secondary;

            &::before {
                opacity: 0;
            }
        }
    }

    @keyframes btn-blinking {
        0% {
            background-color: #ff00c864;
        }

        50% {
            background-color: $color-secondary;
            box-shadow: 0px 0px 10px 1px #FFF;
        }

        100% {
            background-color: #ff00c864;
        }
    }

    .box-highlight {
        background-color: #FFF;
        color: $color-primary;
        border-radius: 1rem;
        border: 3px solid $color-secondary;
        box-shadow: 0 1rem 0 $color-secondary;
        padding: 2rem 3rem;
    }

    .all-aboard-tv {
        background-color: #3F1078;
        border-radius: 2rem;
        border: 4px solid #FFF;
        padding: 2rem;
        box-shadow: 0 1rem 0 #9747FF;

        .tv-button {
            position: relative;

            &.btn-1 {
                &:hover {
                    .selected {
                        filter: drop-shadow(2px -15px 13px #FF00C8);
                    }
                }
            }

            &.btn-2 {
                &:hover {
                    .selected {
                        filter: drop-shadow(2px -15px 13px #00E5FF);
                    }
                }
            }

            &.btn-3 {
                &:hover {
                    .selected {
                        filter: drop-shadow(2px -15px 13px #95FFA1);
                    }
                }
            }

            .selected {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                visibility: hidden;
                transition: all .15s;
            }

            &:hover {
                .selected {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        .screens {
            border: 4px solid #FFF;
            border-radius: 1.5rem;
            margin: 1rem;
            background-color: #390B59;
        }

        .screen-content {
            margin: 2rem;
            position: relative;
            color: #FFF;
            border: 4px solid #FFF;
            opacity: 0;
            visibility: hidden;
            transition: all .15s;
            transform: scale(0.9);
        }

        .screen1,
        .screen2,
        .screen3 {
            visibility: hidden;
        }

        &.screen-1 {
            visibility: visible;

            .screen1 {
                .screen-content {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }

            .tv-button {
                &.btn-1 {
                    .selected {
                        opacity: 1;
                        visibility: visible;
                        filter: drop-shadow(2px -15px 13px #FF00C8);
                    }
                }
            }
        }

        &.screen-2 {
            visibility: visible;

            .screen2 {
                .screen-content {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }

            .tv-button {
                visibility: visible;

                &.btn-2 {
                    .selected {
                        opacity: 1;
                        visibility: visible;
                        filter: drop-shadow(2px -15px 13px #00E5FF);
                    }
                }
            }
        }

        &.screen-3 {
            .screen3 {
                .screen-content {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }

            .tv-button {
                &.btn-3 {
                    .selected {
                        opacity: 1;
                        visibility: visible;
                        filter: drop-shadow(2px -15px 13px #95FFA1);
                    }
                }
            }
        }
    }

    @media (width <=$breakpoint-tablet) {
        .box-highlight {
            padding: 1rem 2rem;
        }

        .all-aboard-tv {
            display: flex;
            flex-direction: column;

            .buttons {
                order: 2;
                margin-top: 1rem;
            }

            .tv-button {
                width: 100px;
                margin: 0 1rem;
            }

            .screens {
                margin: 0;
            }

            .btn-dotted {
                padding: 1rem 2rem;
            }
        }
    }

    @media (width <=$breakpoint-mobile) {
        .all-aboard-tv {
            margin-left: 1rem;
            margin-right: 1rem;
            padding: .5rem;
            border-width: 2px;

            .tv-button {
                width: 70px;
                margin: 0 .5rem;
            }

            .screens {
                border-width: 2px;
            }

            .screen-content {
                margin: 1rem;
                border-width: 2px;
            }

            .btn-dotted {
                padding: .5rem 1rem;
                min-height: auto;
                border-radius: .5rem;

                .left-dot,
                .right-dot {
                    display: none;
                }

                &::before {
                    border-radius: .5rem;
                }
            }
        }
    }
}

#games-on-zkcandy {
    .games-block {
        border: 12px solid #FFF;
        position: relative;
        border-radius: 1.4rem;
        transition: all .15s;

        img {
            border-radius: .6rem;
            transition: all .3s;
        }

        .game-name {
            position: absolute;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            font-weight: 900;
            color: #FFF;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            transition: all .3s;
        }

        &::after {
            content: 'Play';
            text-transform: uppercase;
            color: #FFF;
            font-weight: 900;
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            position: absolute;
            font-size: 2rem;
            transition: all .3s;
            opacity: 0;
        }

        &:hover {
            transform: scale(0.98);
            background-color: $color-primary;
            border: 12px solid $color-secondary;
            box-shadow: 0 20px 0 #00000060;

            .game-name {
                opacity: 0;
            }

            img {
                opacity: 0.2;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    @media (width <=$breakpoint-tablet) {
        .games-block {
            border-width: 6px;
            border-radius: 1rem;

            .game-name {
                font-size: 1.3rem;
            }

            &::after {
                font-size: 1.3rem;
            }
        }
    }

    @media (width <=$breakpoint-mobile) {
        .games-block {
            border-width: 4px;
            border-radius: .8rem;

            .game-name {
                font-size: 1rem;
            }

            &::after {
                font-size: 1rem;
            }

            &:hover {
                border-width: .4rem;
            }
        }
    }
}

#testnet-result {
    background: url('./assets/images/testnet-bg-2.webp');
    // min-height: 55svh;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.44;
    }


    // .testnet-bg {
    //     background: url('./assets/images/testnet-bg-2.webp');
    //     min-height: 100svh;
    //     background-size: cover;
    //     background-position: center;
    // }

    // @media (width <=$breakpoint-tablet) {
    //     .testnet-bg {
    //         min-height: 50svh;
    //     }
    // }

    // @media (width <=$breakpoint-mobile) {
    //     min-height: 100svh;

    //     .testnet-bg {
    //         min-height: 55svh;
    //         background-size: contain;
    //     }

    //     br {
    //         display: block;
    //     }
    // }
}

#stay-connected {
    background: url('./assets/images/stay-connected-bg.webp');
    min-height: 100svh;

    .stay-input {
        border: 3px solid #000;
        padding: .5rem;
        border-radius: .5rem;
    }

    a {
        transition: all .15s;

        &:hover {
            scale: 0.9;
            filter: drop-shadow(0px 0px 3px #FFF);
        }
    }
}

.terms-conditions {
    padding-top: 6em;
    padding-bottom: 3em;
    font-weight: 300;
    text-align: justify;
    background: url('./assets/images/all-aboard-bg.webp');
    background-size: contain;
    color: #FFF;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.4;
    }

    .container {
        max-width: 1200px;
        width: 90%;
    }

    .main-title {
        font-weight: 700;
        margin-bottom: 12px;
    }

    .sub-title {
        margin-bottom: 6px;
        font-weight: 600;
    }

    h1,
    h2 {
        font-size: 24px;
        text-align: center;
    }

    b,
    a {
        font-weight: 700;
    }

    p {
        margin-bottom: 16px;
    }

    ol:not(.roman, .list-additional) {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;

        >li {
            display: table;
            counter-increment: item;
            margin-bottom: 1em;

            &:before {
                content: counters(item, ".") ". ";
                display: table-cell;
                padding-right: .5em;
            }
        }
    }

    .roman {
        list-style-type: lower-roman;
        padding-left: 1em;
    }

    .list-additional {
        list-style-type: none;
        padding-left: 0;

        p {
            margin-bottom: 32px;
        }
    }

    @media (width <=820px) {
        font-size: 14px;

        h1,
        h2 {
            font-size: 20px;
        }
    }
}

.privacy-policy {
    padding-top: 6em;
    padding-bottom: 3em;
    font-weight: 300;
    text-align: justify;
    background: url('./assets/images/all-aboard-bg.webp');
    background-size: contain;
    color: #FFF;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #000;
        opacity: 0.4;
    }

    .container {
        max-width: 1200px;
        width: 90%;
    }

    .main-title {
        font-weight: 700;
        margin-bottom: 12px;
    }

    .sub-title {
        margin-bottom: 6px;
        font-weight: 600;
    }

    h1,
    h2 {
        font-size: 24px;
        text-align: center;
    }

    b,
    a {
        font-weight: 700;
    }

    p {
        margin-bottom: 16px;
    }

    ol:not(.roman, .list-additional) {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;

        >li {
            display: table;
            counter-increment: item;
            margin-bottom: 1em;

            &:before {
                content: counters(item, ".") ". ";
                display: table-cell;
                padding-right: .5em;
            }
        }
    }

    .roman {
        list-style-type: lower-roman;
        padding-left: 1em;
    }

    @media (width <=820px) {
        font-size: 14px;

        h1,
        h2 {
            font-size: 20px;
        }
    }
}